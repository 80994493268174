<template>
  <div class="stock_request">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="applyNum" placeholder="搜索申请单号" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-select v-model="auditStatus" placeholder="审核进度" @change="onSearch" clearable>
          <el-option label="未完成" value="0"></el-option>
          <el-option label="已完成" value="1"></el-option>
        </el-select>
        <el-button class="btn" type="primary" plain @click="onDownload">下载模板</el-button>
        <el-button class="btn" type="primary" plain @click="onImport">导入</el-button>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="180" show-overflow-tooltip prop="applyNum" label="申请单号"></el-table-column>
          <el-table-column min-width="160" show-overflow-tooltip prop="createdTime" label="申请时间"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="" label="审核进度">
            <template slot-scope="scope">
              <span v-if="scope.row.auditStatus == 0">未完成</span>
              <span v-if="scope.row.auditStatus == 1">已完成</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="操作" header-align="center" align="center" width="120" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" plain @click="handleShow(scope.row.id)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
    </div>

    <el-dialog title="导入" :visible.sync="dialogVisible" width="800px">
      <el-form ref="form" label-width="150px">
        <el-form-item label="上传文件" required>
          <el-upload class="upload_demo" ref="upload" :action="action" accept=".xls,.xlsx" :on-change="handleChange" :on-exceed="handleExceed" :on-remove="handleRemove" :file-list="fileList" :limit="1" :auto-upload="false">
            <el-input slot="trigger" placeholder="请选择文件">
              <template slot="suffix">
                <el-button size="small" type="primary">选取文件</el-button>
              </template>
            </el-input>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <div class="err" v-show="showError">
            <span>存在非平台商品，请删除或进行新品申请后再提交。详见</span>
            <span @click="onDownloadError">下载文件</span>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      applyNum: "",
      auditStatus: "",
      tableData: [],
      fileList: [],
      action: this.$axios.defaults.baseURL + this.$api.uploadFile,
      // headers: { token: this.$store.state.token },
      showError: false,
      errStr: "",

      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("productStockAddList"))
    this.applyNum = obj?.applyNum
    this.auditStatus = obj?.auditStatus
    this.currentPage = obj?.currentPage
    this.$saveOrUpdateCache("productStockAddList", {})

    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      this.$axios
        .get(this.$api.shopInventoryApplypageList, {
          params: {
            auditStatus: this.auditStatus,
            applyNum: this.applyNum,
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list
            this.totalItemsCount = res.data.result.totalCount
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 搜索
    onSearch() {
      this.tableData = []
      this.getList()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getList()
    },
    onDownload() {
      window.open("https://xn--fcs331b8yd3xj5in.com/files/excel/库存申请模版.xlsx")
    },
    onDownloadError() {
      window.open(this.errStr)
    },
    onImport() {
      this.fileList = []
      this.dialogVisible = true
    },
    handleExceed() {
      this.$message.warning("最多只能选择一个文件")
    },
    handleRemove() {
      this.fileList = []
      this.$refs.upload.clearFiles()
    },
    handleChange(file, fileList) {
      const fileExtension = file.name.split(".").pop().toLowerCase()
      if (fileExtension == "xls" || fileExtension == "xlsx") {
        this.fileList = fileList
      } else {
        this.$message.error("仅支持 .xls 和 .xlsx 类型的文件，请重新选择！")
        this.fileList = []
        this.$refs.upload.clearFiles()
      }
    },
    onCancel() {
      this.$refs.upload.clearFiles()
      this.dialogVisible = false
    },
    onSubmit() {
      const file = this.fileList[0]?.raw
      if (this.fileList.length == 0) {
        this.$message.error("请选择文件")
      } else {
        let params = new FormData()
        params.append("file", file)
        this.$axios
          .post(this.$api.shopInventoryApplyimportShopInventoryApply, params)
          .then(res => {
            const { code, result } = res.data
            console.log("🚀 ~ this.$axios.post ~ result:", result)
            if (code == 100) {
              this.$message.success("导入成功")
              this.dialogVisible = false
              this.getList()
            }
          })
          .catch(res => {
            const { code, result } = res.data
            if (code == 1008) {
              this.errStr = result
              this.showError = true
            }
          })
      }
    },
    handleShow(id) {
      let obj = {
        auditStatus: this.auditStatus,
        applyNum: this.applyNum,
        currentPage: this.currentPage,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("productStockAddList", obj)
      this.$router.push({ path: "/product/detail/requireDetail", query: { id } })
    },
  },
}
</script>

<style scoped lang="scss">
.stock_request {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    .el-input {
      width: 180px;
      margin-bottom: 20px;
    }

    .el-input,
    .el-select,
    .el-button {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }
  .err {
    color: red;
    span:last-child {
      color: #069bbc;
      margin-left: 5px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
